<div class="modal-wrapper">
    <section class="content-page container-fluid">
        <div>
            <iframe title="Denuncia Ética" src="https://agd.com.ar/comunicacion/denuncia-etica"></iframe>
        </div>
        <hr />
        <div class="text-center">
            <button type="submit" class="btn btn-primary" (click)="closeDialog()">Cerrar</button>
        </div>
    </section>
</div>
